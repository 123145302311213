import React, { useEffect, useState } from "react";
import { DatePickerView, PickerView, Image, Toast } from "antd-mobile";
import DatePicker from "../../components/Model/DatePicker";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import moment from "moment";
import Http from "../../utils/Http";
import { Spin } from "antd";

const now = new Date();
const btnDown = require("./assets/sys_btn_down@2x.png");

const WithdrawalList = () => {
  const [value, setValue] = useState(now);
  const [isShow, setIsShow] = useState(true);
  const navigate = useNavigate();
  const dateRef = React.useRef();
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [money, setMoney] = useState("");

  const getData = async (clean) => {
    if (loading) return;
    try {
      const res = await Http.postForm("/api/v1/wechat_public/withdrawRecord", {
        time: moment(value).format("YYYY-MM"),
        per: 10,
        page: clean ? 1 : page + 1,
      });
      if (res.status === 10000) {
        setPage(res.page);
        setHasNext(res.has_next);
        if (clean) {
          //   res.data.push({
          //     created_at: "2023-09-25 20:00:00",
          //     id: 1,
          //     get_amount: "95",
          //     total_amount: "100",
          //   });
          setData(res.data);
        } else {
          setData((old) => {
            return old.concat(data);
          });
        }
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    } finally {
      setLoading(false);
    }
  };

  const getMoney = async () => {
    if (loading) return;
    try {
      const res = await Http.postForm("/api/v1/wechat_public/withdrawAmount", {
        time: moment(value).format("YYYY-MM"),
      });
      if (res.status === 10000) {
        setMoney(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(true);
    getMoney();
  }, [value]);

  return (
    <div className="withdrawallist-page">
      <DatePicker
        ref={dateRef}
        date={value}
        onChange={(date) => {
          setValue(date);
        }}
      />
      <div
        style={{
          textAlign: "left",
          paddingTop: 12,
          paddingBottom: 13,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 16,
          paddingRight: 16,
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            dateRef.current.show();
          }}
        >
          <span>{moment(value).format("YYYY年MM月")}</span>
          <Image src={btnDown} width={14} height={14} />
        </div>
        <div>
          <span>
            累计到账 ¥ {money === "" ? 0 : parseFloat(money).toFixed(2)}{" "}
          </span>
        </div>
      </div>
      <div
        style={{ height: 0.5, width: "100%", backgroundColor: "#F6F6F6" }}
      ></div>
      <div className="withdrawallist">
        {data?.map((item) => (
          <div
            key={item.tx_no}
            className="withdrawallist-item"
            onClick={() => {
              navigate("/withdrawalDetail", { state: { item: item } });
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  color:
                    item.status === 0
                      ? "#E9A900"
                      : item.status === 1
                      ? "#050303"
                      : "#FE4447",
                }}
              >
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {item.status === 0 && "等待审核"}
                  {item.status === 1 && "提现成功"}
                  {item.status === 2 && "提现拒绝"}
                </span>
                <span style={{ fontSize: 15, fontWeight: 500 }}>-到</span>
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  {item.get_type === 1 && "支付宝"}
                </span>
                <br></br>
                <span style={{ fontSize: 15, fontWeight: 500 }}>
                  ({item.get_account})
                </span>
              </div>

              <span
                style={{
                  fontSize: 12,
                  color: "#979797",
                }}
              >
                {moment(item.created_at).format("MM-DD HH:mm")}
              </span>
            </div>

            {moment(item.created_at) < moment("2023-09-25 20:00:00") ? (
              <div
                style={{
                  fontSize: 15,
                  color:
                    item.status === 0
                      ? "#E9A900"
                      : item.status === 1
                      ? "#050303"
                      : "#FE4447",
                  fontWeight: 600,
                }}
              >
                到账：¥ {item.get_amount}
              </div>
            ) : (
              <div
                style={{
                  fontSize: 15,
                  color:
                    item.status === 0
                      ? "#E9A900"
                      : item.status === 1
                      ? "#050303"
                      : "#FE4447",
                  fontWeight: 600,
                }}
              >
                到账：¥ {item.get_amount}
                <br />
                <span style={{ fontSize: 12, color: "black" }}>
                  提交金额: {item.total_amount}
                </span>
              </div>
            )}
          </div>
        ))}
        <div>
          {loading ? (
            <Spin />
          ) : (
            <div
              onClick={() => {
                if (hasNext) {
                  getData();
                }
              }}
              style={{ padding: 20 }}
            >
              {hasNext ? "点击加载更多" : "已加载全部数据"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawalList;
