import React, { useEffect, useState, useRef } from "react";
import {
  DatePickerView,
  PickerView,
  Image,
  Toast,
  Dialog,
  Tag,
  Button,
} from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import moment from "moment";
import Http from "../../utils/Http";
import { Spin } from "antd";
import { log } from "@craco/craco/lib/logger";
import BuildPay from "../../components/Model/BuildPay";

const now = new Date();
// const btnDown = require('./assets/sys_btn_down@2x.png');
const edit = require("./assets/edit2.png");
const delet = require("./assets/delet.png");
const addimg = require("./assets/add.png");
const alipay = require("../Login/Withdraw/assets/icon_main_zhifu_alipay@2x.png");
const Unionpay = require("../Login/Withdraw/assets/Unionpay.png");
const AccountList = () => {
  const parentRef = useRef();
  const buildRef = React.useRef();
  const navigate = useNavigate();
  const [value, setValue] = useState("now");
  const [data, setData] = useState([]);
  const [items, setItems] = useState([
    // { name: "张尚", idcard: "1867897671", active: false, ids: 1 },
    // { name: "王帅", idcard: "1867897672", active: false, ids: 2 },
    // { name: "张夏", idcard: "1867897673", active: false, ids: 3 },
  ]);
  const [money, setMoney] = useState("");
  //补全信息
  const buquanImg = (all) => {
    buildRef.current.imgText(all);
    buildRef.current.disableds();
  };
  //是否支持银行卡
  const [showBank, setShowBank] = useState(1);
  //添加支付宝还是银行卡
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");

  const getUserInfo = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/userinfo",
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setData(res.data);
      }
    } catch (e) {}
  };

  const getAccountList = async () => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/accountList",
        { category: type },
        undefined,
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        setItems(res.data);
      }
    } catch (e) {}
  };

  const deleteAccountAjax = async (id) => {
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/accountRemove",
        { id: id },
        undefined,
        undefined,
        true
      );
      if (res.status === 10000) {
        getAccountList();
      }
    } catch (e) {}
  };

  const deleteAccont = async (id) => {
    try {
      deleteAccountAjax(id);
    } catch (e) {}
  };
  const handleClick = async (index, items) => {
    try {
      setItems((prevData) => {
        const newData = [...prevData]; // 创建一个新的数组副本
        newData.map((item, i) => {
          if (i === index) {
            item.active = true;
          } else {
            item.active = false;
          }
        });
        navigate(
          "/withdraw?idcard=" +
            items.id_number +
            "&name=" +
            items.name +
            "&ids=" +
            items.account +
            "&id=" +
            items.id +
            "&category=" +
            items.category
        );
        return newData; // 返回更新后的数据
      });
    } catch (e) {}
  };
  //字典配置项目 是否支持银行卡
  const typeInspect = async () => {
    try {
      const res = await Http.postForm("/api/v1/common/getKeyConfig", {
        key: "business_guild_bank_card_switch",
      });
      if (res.status === 10000) {
        setShowBank(res.data);
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
      Toast.show(e.message);
    }
  };
  useEffect(() => {
    getUserInfo();
    getAccountList();
    typeInspect();
  }, [value]);
  //   const items = [
  //     { name: "张尚", idcard: "1867897678" },
  //     { name: "王帅", idcard: "1867897678" },
  //     { name: "张夏", idcard: "1867897678" },
  //   ];
  return (
    <div className="withdrawallist-page">
      <BuildPay
        onFinish={() => {
          getUserInfo();
          getAccountList();
        }}
        ref={buildRef}
      />
      {items.map((item, index) => (
        <div key={index} className={item.active ? "data_ouB" : "data_out"}>
          {item.category === 1 ? (
            <Image
              style={{ marginLeft: 4 }}
              src={alipay}
              width={40}
              height={40}
            />
          ) : (
            <Image
              style={{ marginLeft: 4 }}
              src={Unionpay}
              width={40}
              height={40}
            />
          )}

          <div className="data_out_text">
            <div style={{ fontSize: 16 }}>
              {item.name}
              {/* 上传照片使用 certification_status=1 正常 ，2 补全照片和号码 ，3补全照片，4补全号码 5.手机号 6.银行卡禁用*/}
              <Tag
                // color={item.certification_status === 1 ? "primary" : "warning"}
                color={
                  item.certification_status === 1
                    ? "primary"
                    : item.certification_status === 6
                    ? "#e93f3c"
                    : "warning"
                }
                style={{ marginLeft: 10 }}
              >
                {(item.certification_status === 2 ||
                  item.certification_status === 3 ||
                  item.certification_status === 4 ||
                  item.certification_status === 5) &&
                  "补全信息"}
                {item.certification_status === 1 && "正常"}
                {item.certification_status === 6 && "禁用"}
              </Tag>
              {/* status=1 正常 ，0 审核中 ，2禁用*/}
              {/* <Tag
                color={
                  item.status === 1
                    ? "primary"
                    : item.status === 2
                    ? "#e93f3c"
                    : "warning"
                }
                style={{ marginLeft: 10 }}
              >
                {item.status === 0 && "审核中"}
                {item.status === 1 && "正常"}
                {item.status === 2 && "已禁用"}
              </Tag> */}
            </div>
            <div style={{ fontSize: 12, color: "#999" }}>
              {item.category === 1 ? "支付宝账号" : "银行卡账号"}:{" "}
              {item.account}
            </div>
            {/* <div style={{ fontSize: 12, color: "#ec8c2a" }}>{item.remark}</div> */}
          </div>
          <div className="data_right">
            {/* <div>
              <Image
                style={{ marginLeft: 4 }}
                src={edit}
                width={25}
                height={25}
              />
            </div> */}
            <Button
              color="primary"
              size="mini"
              disabled={item.certification_status !== 1}
              style={{ marginRight: 10 }}
              onClick={() => {
                handleClick(index, item);
              }}
            >
              选择{" "}
            </Button>

            {(item.certification_status === 2 ||
              item.certification_status === 3 ||
              item.certification_status === 4 ||
              item.certification_status === 5) && (
              <Button
                color="warning"
                size="mini"
                style={{ marginRight: 10 }}
                onClick={() => {
                  buquanImg(item);
                }}
              >
                补全{" "}
              </Button>
            )}
            <Button
              color="danger"
              size="mini"
              onClick={() =>
                Dialog.confirm({
                  content: "确认删除该账号吗？",
                  onConfirm: async () => {
                    // await sleep(3000);
                    deleteAccont(item.id);
                    Toast.show({
                      icon: "success",
                      content: "删除成功",
                      position: "bottom",
                    });
                  },
                })
              }
            >
              删除
            </Button>
          </div>
        </div>
      ))}
      {/*  display: "flex", gap: "10px"  */}
      <div style={{}}>
        {type == 1 ? (
          <div
            className="add_box"
            onClick={() => {
              buildRef.current.show("alipay");
            }}
          >
            <Image
              style={{ marginRight: 6 }}
              src={addimg}
              width={15}
              height={15}
            />
            <Image
              style={{ marginRight: 6 }}
              src={alipay}
              width={15}
              height={15}
            />
            添加支付宝账号
          </div>
        ) : (
          ""
        )}

        {showBank == 1 && type == 2 ? (
          <div
            style={{ marginTop: 10 }}
            className="add_box"
            onClick={() => {
              buildRef.current.show("unionpay");
            }}
          >
            <Image
              style={{ marginRight: 6 }}
              src={addimg}
              width={15}
              height={15}
            />
            <Image
              style={{ marginRight: 6 }}
              src={Unionpay}
              width={20}
              height={20}
            />
            添加银行卡账号
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AccountList;
